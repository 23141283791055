import React, { useContext, useState } from "react";

import http from "../configs/http.config";

class UserService {

    check_email(email) {
        return http.get(`/user/check_email/${email}`);
    }
    //create Account
    createAccount(data) {
        return http.post(`/user/create_account`,data);
    }
    //update Account
    updateAccount(id,data,authorization) {
        return http.patch(`/user/update_account/${id}`,data, authorization);
    }
    //get account by id
    getAccount(id,authorization) {
        return http.get(`/user/get_account/${id}`, authorization);
    }
    //update avatar
    updateAvatar(id,data,authorization) {
        return http.post(`/user/update_avatar/${id}`,data,authorization);
    }
}
export default new UserService();