import React, { useState, useEffect } from 'react';
import { ProductGrid } from "../products/gridProduct";

function Home() {
    const banner = require('../../../assets/images/element-banner.png')
    const instagram = require('../../../assets/images/social/Instagram.png')
    const facebook = require('../../../assets/images/social/facebook.png')
    const linkedIn = require('../../../assets/images/social/linkedin.png')
    return (
        <>
            <header className="home">
                <div className=" px-5">
                    <div className="row gx-5 align-items-center">
                        <div className="col-lg-6">
                            <div className="mb-5 mb-lg-0 text-center text-lg-start">
                                <h2 className="display-6 ">Customized your</h2>
                                <h1 className="display-4 ">shopping experience</h1>
                                <p className="lead fw-normal">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore </p>
                                <button type="button" className="btn btn-banner mt-2"><i className="fa-solid fa-phone mx-2"></i> Call Now</button>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="masthead-device-mockup">
                                <img className='img-fluid banner-img' src={banner} />
                                <div className='float-end align-middle'>
                                    <div> <img className='' src={instagram} /></div>
                                    <div> <img className='mt-3' src={facebook} /></div>
                                    <div>  <img className='mt-3' src={linkedIn} /></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <ProductGrid />
        </>
    )
}
export { Home };

