import React from "react";

import Image from 'react-bootstrap/Image'


function Footer() {
    const logoFooter = require('../../assets/images/footer/logo_white.png')

    return (
        <>
            <div className="footer-background-top ">
            </div>
            <footer className="footer">
                <ul className="footer-nav justify-content-center border-bottom mb-3">
                    <li className="footer-nav-item d-flex"><img className="footer-img  mt-2" src={logoFooter} /><p className="footer-p-image mx-2">© 2023, Anic</p></li>
                    <li className="footer-nav-item">  <p className=""><i className="fa-regular fa-envelope"></i> ingo@anic.us</p></li>
                    <li className="footer-nav-item"><p> <i className="fa-solid fa-location-dot"></i> 2290W 8th Ave, unidad C, hialeah, Fl 33010</p></li>

                </ul>
            </footer>
        </>
    )
}
export { Footer };
