import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, NavLink, Navigate } from 'react-router-dom'

//auth import
import { useAuth } from '../../../context/AuthProvider.js';
import AuthService from '../../../services/auth.js'

//footer
import { FooterAuth } from '../../layouts/footerAuth'

//bootstrap
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

//formik
import { Field, useFormik } from "formik";
import * as Yup from "yup";

//btn svg
import facebook from '../../../assets/images/auth/facebook.svg';
import google from '../../../assets/images/auth/google.png';
import apple from '../../../assets/images/auth/apple.svg';
import btnShow from '../../../assets/images/auth/btn-show.svg';

//validation email
const emailFaild = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
const validationSchema = Yup.object().shape({
    email: Yup.string()
        .matches(emailFaild, 'Email is not valid')
        .required("Required"),
});


function Recovery() {
    //navigate
    const navigate = useNavigate();
    

    const bannerLogin = require('../../../assets/images/element-banner.png')
    const [emailUser, setEmailUser] = useState('');

    //message error
    const [message, setMessage] = useState(null);

    //on submit 
    const onSubmit = async (values) => {
        setEmailUser(values.email);
        setShow(true);
    }

    //send email
    const sendEmail = async (values) => {
            try {
                const recoveryEmail = await AuthService.recovery(emailUser);
                //console.log(recoveryEmail)
                if(recoveryEmail.data.statusCode===200){
                    //console.log(recoveryEmail.data.statusCode)
                    setMessage({ error: false, msg:recoveryEmail.data.message});
                    setShow(false);
                }else if(recoveryEmail.data.statusCode===404){
                    setMessage({ error: true, msg:'Tray again! Email no valid'});
                    setShow(false);
                }
            } catch (error) {
                setMessage({ error: true, msg:'Try again! An error has occurred'});
                setShow(false);
            }
       // console.log(emailUser)
       // setShow(false);
    }

    //verify input
    const formik = useFormik({
        initialValues: { email: "" },
        validateOnBlur: true,
        onSubmit,
        validationSchema: validationSchema,
    });
    //modal
    const [show, setShow] = useState(false);
    const handleClose = () => { setShow(false) }
    const handleShow = () => setShow(true);

    return (
        <>
            <section className='login'>
                <div className='container mt-5'>
                    <div className='row'>
                        <div className='col-md-6'>
                            <img className='img-fluid mx-auto d-block mt-5 mb-3' src={bannerLogin} />
                        </div>
                        <div className='col-md-6'>
                            <div className='col-md-8 float-end login-form  '>
                                {message ?
                                    <div className={`alert aletr-auth-login alert-${message.error ? 'danger' : 'success'}`} role="alert">
                                        <strong>{message.msg}</strong>
                                    </div>
                                    : null
                                }
                                <form className=" needs-validation" onSubmit={formik.handleSubmit} >
                                    <h1 className="h3 mb-3 login-title">Recovery</h1>
                                    <div className="mb-3 ">
                                        <input
                                            name="email"
                                            type="text"
                                            placeholder="Email "
                                            value={formik.values.email}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            className="form-control"
                                        />
                                        {formik.errors.email && formik.touched.email && (
                                            <div className="input-feedback">{formik.errors.email}</div>
                                        )}
                                    </div>

                                    <button className="w-100 btn btn-lg btn-login mt-3" disabled={!formik.isValid} type="submit">SEND EMAIL</button>
                                </form>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <div className='mt-4'>
                                        </div>
                                    </div>
                                    <div>
                                        <hr className='mt-3' />
                                    </div>
                                    {/*                                     <div className="d-grid gap-2 d-md-block  text-center btn-social-singin mt-">
                                        <button className="btn btn-singin btn-sm mx-3 mt-1" type="button"><img src={facebook} className='mx-2' />Facebook</button>
                                        <button className="btn btn-singin btn-sm mx-3 mt-1" type="button"><img src={google} className='mx-2' />Google</button>
                                    </div> */}
                                    <div className='mt-2'>
                                        <p className='privicy-policy'><NavLink>terms of services & privacy policy</NavLink></p>
                                        <p className='privicy-policy'>You have an account? <NavLink to={'/login'}> Log in</NavLink></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <FooterAuth />

            {/*  Modal */}
            <Modal show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                centered className=' modal-login'>
                <div className="modal-content content">
                    <div className="modal-body">
                        <p className='text-center'> You will receive a Email verification code sent to {emailUser}</p>
                    </div>
                    <div className="modal-footer footer-login-modal  d-md-block  text-center">
                        <button type="button" className="btn btn-cancel " onClick={handleClose}>Close</button>
                        <button type="button" className="btn btn-sm btn-send " onClick={sendEmail}>Send</button>
                    </div>
                </div>
            </Modal>

        </>
    )
}
export { Recovery };
