import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import productService from "../../../services/product.service";

function ProductGrid() {
    const backgroundCard = require('../../../assets/images/products/background-banner.jpg')
    const [products, setProducts] = useState([]);
    const [placeholder, setPlaceHolder] = useState(false);
    //console.log(process.env.REACT_APP_API_URL);


    useEffect(() => {
        getProducts();
    }, []);
    const getProducts = async (e) => {
        try {
            const getProducts = await productService.getProducts();
            // console.log(getProducts.data)
            if (getProducts.status === 200) {
                 setProducts(getProducts.data);
                 setPlaceHolder(true);
            }
        } catch (error) {
            setPlaceHolder(false);
        }

    }

    return (
        <>
            {
                !placeholder
                    ? <section className='placeholder-glow'>
                        <div className='background-banner-grid-product mt-4'>
                            <h1 className='text-center'><p className='placeholder w-25'></p> </h1>
                        </div>
                        <div className="container">
                            <div className="row ">
                                <div className="col-md-4 mt-5">
                                    <div className="card cards">
                                        <div className='float-md-right'>
                                            <img className="img-product float-end" alt="..." />
                                        </div>
                                        <div className="card-img-overlay">

                                            <div className="mx-4">
                                                <h6 className="card-title mt-3  placeholder col-1"></h6>
                                                <h4 className="card-title mt-3 "></h4>
                                                <button type="button" className="btn btn-grid mt-4  disabled placeholder col-4"></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    : <section>
                        <div className='background-banner-grid-product mt-4'>
                            <h1 className='text-center'>Select <b>Product</b> </h1>
                        </div>
                        <div className="container">
                            <div className="row">
                                {
                                    products.map((product, i) => {
                                        return (
                                            <div className="col-md-4 mt-5" key={product.id}>
                                                <div className="card cards">
                                                    <div className='float-md-right'>
                                                        <img src={product.imageURL} className="img-product float-end" alt="..." />
                                                    </div>
                                                    <div className="card-img-overlay">

                                                        <div className="mx-4">
                                                            <h6 className="card-title mt-3">Personalize</h6>
                                                            <h4 className="card-title mt-3">{product.name.substring(0, 10)}</h4>
                                                            <NavLink to={`/product/step-product/${product.id}`}><button type="button" className="btn btn-grid mt-4">COME ON</button></NavLink>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </section>
            }

        </>
    )
}
export { ProductGrid };

