import React, { useContext, useState } from "react";

import http from "../configs/http.config";

class OrderService {

    getOrderById(id,authorization) {
        return http.get(`/order/${id}`,authorization);
    }

    getOrderByUser(id,authorization) {
        return http.get(`/order/user/${id}`,authorization);
    }


}
export default new OrderService();