import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, NavLink, Navigate } from 'react-router-dom'

//auth import
import { useAuth } from '../../../context/AuthProvider.js';
import UserService from '../../../services/user.service.js'

//footer
import { FooterAuth } from '../../layouts/footerAuth'

//bootstrap
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';

//formik
import { Field, useFormik } from "formik";
import * as Yup from "yup";

//btn svg
import facebook from '../../../assets/images/auth/facebook.svg';
import google from '../../../assets/images/auth/google.png';
import apple from '../../../assets/images/auth/apple.svg';
import btnShow from '../../../assets/images/auth/btn-show.svg';

//validation phone
//const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
const validationSchema = Yup.object().shape({
    email: Yup.string()
        //.matches(phoneRegExp, 'Phone number is not valid')
        .email()
        .required("Required"),
});


function SignUp() {
    //navigate
    const navigate = useNavigate();

    const [emailCheck, setEmailCheck] = useState('');

    const bannerLogin = require('../../../assets/images/element-banner.png')
    const [passwordShown, setPasswordShown] = useState(false);

    //message error
    const [message, setMessage] = useState(null);
    //alert
    const [showAlert, setShowAlert] = useState(true);

    const onSubmit = async (values) => {
        //console.log(values.email);
        setEmailCheck(values.email)
        setShow(true);
    }

    //verify input
    const formik = useFormik({
        initialValues: { email: "" },
        validateOnBlur: true,
        onSubmit,
        validationSchema: validationSchema,
    });
    //modal
    const [show, setShow] = useState(false);
    const handleClose = () => { setShow(false) }
    const handleShow = () => setShow(true);

    //send confirmation email
    const sendEmail = async (values) => {
        setShow(false);
        try {
            //console.log(emailCheck)
            const sendEmail = await UserService.check_email(emailCheck);
            console.log(sendEmail)
            if (sendEmail.status === 200 && sendEmail.data.statusCode === 200) {
                setMessage({ error: true, msg: "Account already exists! try again" });
                setShowAlert(true);
            }else if (sendEmail.status === 200 && sendEmail.data.output.payload.statusCode === 404) {
                //email no exist. create acount      
                navigate(`/create-account/${emailCheck}`); 
            }
           // console.log(sendEmail.data.output.payload.statusCode)
        } catch (error) {
            setShowAlert(true);
            setMessage({ error: true, msg: "try again! something happened" });
        }
    }

    return (
        <>
            <section className='login'>
                <div className='container mt-5'>
                    <div className='row'>
                        <div className='col-md-6'>
                            <img className='img-fluid mx-auto d-block mt-5 mb-3' src={bannerLogin} />
                        </div>
                        <div className='col-md-6'>
                            <div className='col-md-8 float-end login-form  '>
                                {message ?
                                    <Alert show={showAlert} variant={`${message.error ? 'danger' : 'success'}`}>
                                        <p>{message.msg}</p>
                                       <hr />
                                        <div className="d-flex justify-content-end">
                                            <Button onClick={() => setShowAlert(false)} size="sm" variant={`outline-${message.error ? 'danger' : 'success'}`}>
                                                Close
                                            </Button>
                                        </div>
                                    </Alert>
                                    : null
                                }

                                <form className=" needs-validation" onSubmit={formik.handleSubmit} >
                                    <h1 className="h3 mb-3 login-title">SIG UP</h1>
                                    <div className="mb-3 ">
                                        <input
                                            name="email"
                                            type="text"
                                            placeholder="Email"
                                            value={formik.values.email}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            className="form-control"
                                        />
                                        {formik.errors.email && formik.touched.email && (
                                          <div className="form-text login-create-help text-danger">{formik.errors.email}</div>
                                        )}
                                    </div>

                                    <button className="w-100 btn btn-lg btn-login mt-3" disabled={!formik.isValid} type="submit">FOLLOWING</button>
                                </form>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <div className='mt-4'>
                                        </div>
                                    </div>
                                    <div>
                                        <hr className='mt-3' />
                                    </div>
                                    <div className="d-grid gap-2 d-md-block  text-center btn-social-singin mt-">
                                        <button className="btn btn-singin btn-sm mx-3 mt-1" type="button"><img src={facebook} className='mx-2' />Facebook</button>
                                        <button className="btn btn-singin btn-sm mx-3 mt-1" type="button"><img src={google} className='mx-2' />Google</button>
                                    </div>
                                    <div className='mt-2'>
                                        <p className='privicy-policy'>By signing up, you agree to the <NavLink>terms of services & privacy policy</NavLink></p>
                                        <p className='privicy-policy'>You have an account? <NavLink to={'/login'}> Log in</NavLink></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <FooterAuth />

            {/*  Modal */}
            <Modal show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                centered className=' modal-login'>
                <div className="modal-content content">
                    <div className="modal-body">
                        <p className='text-center'> Are you sure to register with this email {emailCheck} ?</p>
                    </div>
                    <div className="modal-footer footer-login-modal  d-md-block  text-center">
                        <button type="button" className="btn btn-cancel " onClick={handleClose}>Close</button>
                        {/* <NavLink to={'/verifycode'}><button type="button" className="btn btn-sm btn-send ">Send</button></NavLink> */}
                        <button type="button" onClick={sendEmail} className="btn btn-sm btn-send ">Send</button>
                    </div>
                </div>
            </Modal>

        </>
    )
}
export { SignUp };
