import React, { useContext, useState } from "react";

import http from "../configs/http.config";

class AuthService {

    login(data) {
        return http.post("/auth", data).then(response => {
            return response
        })
    }
  
    //send email
    recovery(email) {
        return http.post(`/auth/recovery/${email}`);
    }
    //change new password
    changePassword(password, authorization) {
        return http.get(`/auth/changepassword/${password}`, authorization);
    }
    //create Account
    createAccount(data) {
        return http.post(`/auth/create_account`,data);
    }
    //update Account
    updateAccount(id,data,authorization) {
        return http.patch(`/auth/update_account/${id}`,data, authorization);
    }
    //get account by id
    getAccount(id,authorization) {
        return http.get(`/auth/get_account/${id}`, authorization);
    }
    //update avatar
    updateAvatar(id,data) {
        return http.post(`/auth/update_avatar/${id}`,data);
    }
}
export default new AuthService();