import React, { useState, useEffect, useContext } from 'react';
import { NavLink,Navigate,useNavigate,useLocation} from 'react-router-dom'

//auth import
import { useAuth,AuthRoute } from '../../../context/AuthProvider.js';
import AuthService from '../../../services/auth.js'

//bootstrap
import Form from 'react-bootstrap/Form';

//formik
import { Field, useFormik } from "formik";
import * as Yup from "yup";

//footer
import { FooterAuth } from '../../layouts/footerAuth'

//btn svg
import facebook from '../../../assets/images/auth/facebook.svg';
import google from '../../../assets/images/auth/google.png';
import apple from '../../../assets/images/auth/apple.svg';
import btnShow from '../../../assets/images/auth/btn-show.svg';

//validation
const validationSchema = Yup.object().shape({
    email: Yup.string()
        .email()
        .required("Required"),
    password: Yup.string()
        .required("No password provided.")
        .min(8, "Password is too short - should be 8 chars minimum.")
        .matches(/(?=.*[0-9])/, "Password must contain a number.")
});

function LogIn() {
    const bannerLogin = require('../../../assets/images/element-banner.png')
    const [passwordShown, setPasswordShown] = useState(false);

    //naviagte
    const navigate = useNavigate();
    const location = useLocation();
  

    //validation auth
    const auth = useAuth();

    //auth
    const [email, setEmail] = useState('');//pendiente
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState(null);

    //history route
    
    useEffect(() => {
       //check session
        if (! JSON.parse(sessionStorage.getItem('auth'))) {
          }else{
            navigate("/profile");
          }    
    }, []);

    const onSubmit = async (values) => {
        //  console.log(values)
        const value = '';
        AuthService.login(values).then(response => {
           // console.log(response.data)
            if (response.status == 200) {
                if (response.data?.statusCode == 404) {
                    setMessage({ error: true, msg: 'Not Found' });
                } else if (response.data?.statusCode == 401) {
                    setMessage({ error: true, msg: 'Unauthorized' });
                } else {
                   // console.log(response)
                    const accessToken = response.data?.accessToken;
                    const roles = response.data?.roles;
                    const role = roles.reduce(() => '')
                   // console.log(response.data)
                    sessionStorage.setItem("auth", JSON.stringify({ email: values.email, roles: role, accessToken: accessToken,user:response.data?.userId }));
                    setMessage({ error: false, msg: "Login successfully ... redirecting ... " });
                    setTimeout(() => {
                        auth.loginOnNew(values.email);
                        //veryfi direction to log in
                        if (!location.state?.from) {
                            navigate('/profile');
                          } else {
                            navigate(location.state.from);
                          }
                    }, 2000);
                }
            }
        }).catch(err => {
            console.log(err)
            if (!err?.response.status) {
                setMessage({ error: true, msg: 'No Server Response' });
            } else if (err.response?.status === 400) {
                setMessage({ error: true, msg: 'Missing Username or Password' });
            } else if (err.response?.status === 401) {
                setMessage({ error: true, msg: 'Unauthorized' });
            } else {
                setMessage({ error: true, msg: 'Login Failed' });
            }
        });
    }

    // Password toggle handler
    const togglePassword = () => {
        setPasswordShown(!passwordShown);
    };

    const formik = useFormik({
        initialValues: { email: "", password: "" },
        validateOnBlur: true,
        onSubmit,
        validationSchema: validationSchema,
    });
    return (
        <>
            <section className='login'>
                <div className='container mt-5'>
                    <div className='row'>
                        <div className='col-md-6'>
                            <img className='img-fluid mx-auto d-block mt-5 mb-3' src={bannerLogin} />
                        </div>
                        <div className='col-md-6'>
                            <div className='col-md-8 float-end login-form  '>
                                {message ?
                                    <div className={`alert aletr-auth-login alert-${message.error ? 'danger' : 'success'}`} role="alert">
                                        <strong>{message.msg}</strong>
                                    </div>
                                    : null
                                }
                                <form className=" needs-validation" onSubmit={formik.handleSubmit} >
                                    <h1 className="h3 mb-3 login-title">LOG IN</h1>
                                    <div className="mb-3">
                                        <input
                                            name="email"
                                            type="text"
                                            placeholder="Enter your email"
                                            value={formik.values.email}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            className="form-control"
                                        />
                                        {formik.errors.email && formik.touched.email && (
                                            <div className="form-text login-create-help text-danger">{formik.errors.email}</div>
                                        )}
                                    </div>
                                    <div className=" input-group mb-3  ">
                                        {/* <input type={passwordShown ? "text" : "password"} */}
                                        <input
                                            type={passwordShown ? "text" : "password"}
                                            name="password"
                                            placeholder="Enter your password"
                                            value={formik.values.password}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            className="form-control"
                                        />
                                        <button className="btn btn-show  " type="button" onClick={togglePassword}><img src={btnShow} /></button>
                                    </div>
                                    {formik.errors.password && formik.touched.password && (
                                      <div className="form-text login-create-help text-danger">{formik.errors.password}</div>
                                    )}
                                    <button className="w-100 btn btn-lg btn-login mt-3" disabled={!formik.isValid} type="submit">LOG IN</button>
                                </form>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <div className='mt-4'>
                                            <NavLink to={'/recovery'} className='float-start forgot-password'><p>Forgot Password</p></NavLink>
                                            <NavLink to={'/signup'}><p className='float-end forgot-password'>start with Phone</p></NavLink>
                                        </div>
                                    </div>
                                    <div>
                                        <hr />
                                    </div>
                                    <div className="d-grid gap-2 d-md-block  text-center btn-social-singin mt-2">
                                        <button className="btn btn-singin btn-sm mx-1 mt-1" type="button"><img src={facebook} className='mx-2' />Facebook</button>
                                        <button className="btn btn-singin btn-sm mx-1 mt-1" type="button"><img src={google} className='mx-2' />Google</button>
                                        <button className="btn btn-singin btn-sm mx-1 mt-1" type="button"><img src={apple} className='mx-2' />Apple</button>
                                    </div>
                                    <div className=''>
                                        <NavLink to={'/signup'}><p className='btn-sign'>Sign Up</p></NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <FooterAuth />
        </>
    )
}
export { LogIn };
