import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams, NavLink, Navigate } from 'react-router-dom'

//footer
import { FooterAuth } from '../../layouts/footerAuth'

//auth import
import UserService from '../../../services/user.service'

//formik
import { Field, useFormik, Formik } from "formik";
import * as Yup from "yup";

//bootstrap
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';

//validation
const validationSchema = Yup.object().shape({
    name: Yup.string()
        .required("Required"),
    lastName: Yup.string()
        .required("Required"),
    email: Yup.string()
        .email()
        .required("Required"),
    password: Yup.string()
        .required('Password is required')
        .min(8, "Password is too short - should be 8 chars minimum.")
        .matches(/(?=.*[0-9])/, "Password must contain a number."),
    checkPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
});


function CreateAccount() {
    //image banner 
    const bannerLogin = require('../../../assets/images/element-banner.png')

    //navigate
    const navigate = useNavigate();
    const { email } = useParams();
    const [initialValues, setInitialValues] = useState([]);

    //message error
    const [message, setMessage] = useState(null);
    //alert
    const [showAlert, setShowAlert] = useState(true);

    //modal
    const [show, setShow] = useState(false);
    const handleClose = () => { setShow(false) }
    const handleShow = () => setShow(true);

    //send data create account
    const onSubmitForm = async (values) => {
        //console.log(values)
        let data = {
            name: values.name,
            last_name: values.lastName,
            email: values.email,
            password: values.password
        }
       // console.log(data);
        try {
            const createAccount = await UserService.createAccount(data);
            console.log(createAccount)
            if (createAccount.status === 200 && createAccount.data.statusCode === 201) {
                //rendirect page
                console.log('register')
                setShow(true);
                
            } else {
                //alert no created 
                setShowAlert(true);
                setMessage({ error: true, msg: "Account already exist! try again" });
            }
        } catch (error) {
            setShowAlert(true);
            setMessage({ error: true, msg: "try again! something happened" });
        }
    }
    //formik
    const formik = useFormik({
        initialValues: { name: "", lastName: "", email: "", password: "", checkPassword: "" },
        validateOnBlur: true,
        validationSchema: validationSchema,
    });

    return (
        <>
            <section className='login'>
                <div className='container mt-5'>
                    <div className='row'>
                        <div className='col-md-6'>
                            <img className='img-fluid mx-auto d-block mt-5 mb-3' src={bannerLogin} />
                        </div>
                        <div className='col-md-6 create-account'>
                            {message ?
                                <Alert show={showAlert} variant={`${message.error ? 'danger' : 'success'}`}>
                                    <p>{message.msg}</p>
                                    <hr />
                                    <div className="d-flex justify-content-end">
                                        <Button onClick={() => setShowAlert(false)} size="sm" variant={`outline-${message.error ? 'danger' : 'success'}`}>
                                            Close
                                        </Button>
                                    </div>
                                </Alert>
                                : null
                            }
                            <Formik initialValues={{
                                name: '',
                                lastName: '',
                                email: email,
                                password: '',
                                checkPassword: ''
                            }}
                                onSubmit={onSubmitForm}
                                validationSchema={validationSchema}>
                                {formik => (
                                    <form className=" needs-validation" onSubmit={formik.handleSubmit}>
                                        <h1 className="h3 mb-3 login-title mb-4">Sign Up Now</h1>
                                        <div className="mb-3 create">
                                            <label className="form-label">Name</label>
                                            <input type="text" className="form-control" id="name" name='name'
                                                value={formik.values.name}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur} />
                                            {formik.errors.name && formik.touched.name && (
                                                <div className="form-text login-create-help text-danger">{formik.errors.name}</div>
                                            )}
                                        </div>
                                        <div className="mb-3 create">
                                            <label className="form-label">Last Name</label>
                                            <input type="text" className="form-control" id="lastName" name='lastName'
                                                value={formik.values.lastName}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur} />
                                            {formik.errors.lastName && formik.touched.lastName && (
                                                <div className="form-text login-create-help text-danger">{formik.errors.lastName}</div>
                                            )}
                                        </div>
                                        <div className="mb-3 create">
                                            <label className="form-label">Email</label>
                                            <input type="email" disabled className="form-control" id="email" name='email'
                                                value={formik.values.email}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur} />
                                            {formik.touched.email && formik.errors.email ? (
                                                <div className="form-text login-create-help text-danger">{formik.errors.email}</div>
                                            ) : null}
                                        </div>
                                        <div className="mb-3 create">
                                            <label className="form-label">Password</label>
                                            <input type="password" className="form-control" id="password" name='password'
                                                value={formik.values.password}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.errors.password && formik.touched.password && (
                                                <div className="form-text hlogin-create-helptext-danger">{formik.errors.password}</div>
                                            )}
                                        </div>
                                        <div className="mb-3 create">
                                            <label className="form-label">Confirmation Passsword</label>
                                            <input type="password" className="form-control" id="checkPassword" name='checkPassword'
                                                value={formik.values.checkPassword}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.errors.checkPassword && formik.touched.checkPassword && (
                                                <div className="form-text login-create-help text-danger">{formik.errors.checkPassword}</div>
                                            )}
                                        </div>
                                        <button className="w-50 btn btn-lg btn-login mt-3 " disabled={!formik.isValid} type="submit">Create Account</button>
                                    </form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </section>
            <FooterAuth />
            {/*  Modal */}
            <Modal show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                centered className=' modal-login'>
                <div className="modal-content content">
                    <div className="modal-body">
                        <p className='text-center'> Account has been created!  You will receive an email confirmation.</p>
                    </div>
                    <div className="modal-footer footer-login-modal  d-md-block  text-center">
                        <button type="button" className="btn btn-cancel " onClick={handleClose}>Close</button>
                        <NavLink to={'/login'}><button type="button" className="btn btn-sm btn-send ">Send</button></NavLink>
                    </div>
                </div>
            </Modal>
        </>
    )
}
export { CreateAccount }