import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, NavLink, Navigate, useParams, } from 'react-router-dom'


//auth import
import { useAuth } from '../../../context/AuthProvider.js';
import AuthService from '../../../services/auth.js'

//footer
import { FooterAuth } from '../../layouts/footerAuth'

//bootstrap
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

//formik
import { Field, useFormik } from "formik";
import * as Yup from "yup";

//btn svg
import facebook from '../../../assets/images/auth/facebook.svg';
import google from '../../../assets/images/auth/google.png';
import apple from '../../../assets/images/auth/apple.svg';
import btnShow from '../../../assets/images/auth/btn-show.svg';

const validationSchema = Yup.object().shape({
    password: Yup.string()
        .required('Password is required')
        .min(8, "Password is too short - should be 8 chars minimum.")
        .matches(/(?=.*[0-9])/, "Password must contain a number."),
    passwordConfirmation: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
});

function ResetPassword() {
    const tokenUrl = useParams();
    const navigate = useNavigate();
    const bannerLogin = require('../../../assets/images/element-banner.png')
    const [newpassword, setNewPassword] = useState('');

    //message error
    const [message, setMessage] = useState(null);

    //on submit 
    const onSubmit = async (values) => {
        setNewPassword(values.password);
        //send new pass
        console.log()
        try {
            const token = tokenUrl.token;
            const bearer_Token = {
                'headers': {
                    'Authorization': `Bearer ${token}`
                }
            };
            const urlEncodedValue = encodeURIComponent(values.password);
            const changePassword = await AuthService.changePassword(urlEncodedValue, bearer_Token);
            //console.log(changePassword)
            if (changePassword.data.statusCode === 200) {
                setMessage({ error: false, msg: 'Updated successfully new password' });
                // Set a timeout of 2 seconds
                setTimeout(() => {
                    navigate('/login');
                }, 2000);
            } if (changePassword.data.statusCode === 400) {
                setMessage({ error: false, msg: 'Updated successfully new password' });
            }
        } catch (error) {
            setMessage({ error: true, msg: 'Try again! An error has occurred' });
            //setShow(false);
            console.log(error)
        }
    }

    //verify input
    const formik = useFormik({
        initialValues: { password: "", passwordConfirmation: "" },
        validateOnBlur: true,
        onSubmit,
        validationSchema: validationSchema,
    });
    //modal
    const [show, setShow] = useState(false);
    const handleClose = () => { setShow(false) }
    const handleShow = () => setShow(true);

    return (
        <>
            <section className='login'>
                <div className='container mt-5'>
                    <div className='row'>
                        <div className='col-md-6'>
                            <img className='img-fluid mx-auto d-block mt-5 mb-3' src={bannerLogin} />
                        </div>
                        <div className='col-md-6'>
                            <div className='col-md-8 float-end login-form  '>
                                {message ?
                                    <div className={`alert aletr-auth-login alert-${message.error ? 'danger' : 'success'}`} role="alert">
                                        <strong>{message.msg}</strong>
                                    </div>
                                    : null
                                }
                                <form className=" needs-validation" onSubmit={formik.handleSubmit} >
                                    <h1 className="h3 mb-3 login-title">Change password</h1>
                                    <div className="mb-3 ">
                                        <input
                                            name="password"
                                            type="text"
                                            placeholder="password "
                                            value={formik.values.password}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            className="form-control"
                                        />
                                        {formik.errors.password && formik.touched.password && (
                                            <div className="form-text login-create-help text-danger">{formik.errors.password}</div>
                                        )}
                                        <input
                                            name="passwordConfirmation"
                                            type="text"
                                            placeholder="Validate Password "
                                            value={formik.values.passwordConfirmation}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            className="form-control"
                                        />
                                        {formik.errors.passwordConfirmation && formik.touched.passwordConfirmation && (
                                            <div className="form-text login-create-help text-danger">{formik.errors.passwordConfirmation}</div>
                                        )}
                                    </div>

                                    <button className="w-100 btn btn-lg btn-login mt-3" disabled={!formik.isValid} type="submit">CHANGE PASSWORD</button>
                                </form>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <div className='mt-4'>
                                        </div>
                                    </div>
                                    <div>
                                        <hr className='mt-3' />
                                    </div>
                                    {/*                                     <div className="d-grid gap-2 d-md-block  text-center btn-social-singin mt-">
                                        <button className="btn btn-singin btn-sm mx-3 mt-1" type="button"><img src={facebook} className='mx-2' />Facebook</button>
                                        <button className="btn btn-singin btn-sm mx-3 mt-1" type="button"><img src={google} className='mx-2' />Google</button>
                                    </div> */}
                                    <div className='mt-2'>
                                        <p className='privicy-policy'><NavLink>terms of services & privacy policy</NavLink></p>
                                        <p className='privicy-policy'>You have an account? <NavLink to={'/login'}> Log in</NavLink></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <FooterAuth />

            {/*  Modal */}
            <Modal show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                centered className=' modal-login'>
                <div className="modal-content content">
                    <div className="modal-body">
                        <p className='text-center'> You will receive a Email verification code sent to </p>
                    </div>
                    <div className="modal-footer footer-login-modal  d-md-block  text-center">
                        <button type="button" className="btn btn-cancel " onClick={handleClose}>Close</button>
                        <button type="button" className="btn btn-sm btn-send " >Send</button>
                    </div>
                </div>
            </Modal>

        </>
    )
}
export { ResetPassword };
