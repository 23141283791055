import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom'

//localstorage
import CartProvider from '../../context/cartLocalStorage';

//bootstrap
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image'

//img
import btnCart from '../../assets/images/cart/cart.svg';
import btnLogIn from '../../assets/images/auth/login.svg';

//auth
import { useAuth, AuthRoute } from '../../context/AuthProvider';
function TodoMenu() {
  const { countItemsCart, itemsCart } = React.useContext(CartProvider);
  //validation auth
  const auth = useAuth();
  const [verifyAuth, setVerifyAuth] = useState([false]);

  useEffect(() => {
  }, []);

  //log out session
  const logOut = () => {
    auth.logout();  
  }
  const logo = require('../../assets/images/nav/logo_color.png')
  return (
    <>
      <Navbar expand="lg" className="justify-content-center">
        <Container className="nav-center">
          <Navbar.Brand >
            <NavLink to={'/'}> <Image className="nav-logo" src={logo} />   </NavLink >
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mx-auto">
              <NavLink className="nav-a btn">Maison</NavLink>
              <NavLink className=" nav-a btn" >Treatments</NavLink>
              <NavLink className=" nav-a btn">Nos Services</NavLink>
              <NavLink className=" nav-a btn">Promotions</NavLink>
              <NavLink className=" nav-a btn">Blog</NavLink>
              <NavLink className=" nav-a btn">Nous Contacter</NavLink>
              {/*   <div className="p-4" > <Button className=" nav-button btn-sm" variant="primary">Nous Contacter</Button></div> */}
              <div className="btn-group nav-cart nav-a-btn mx-3">
                <button type="button" className="btn position-relative btn-cart" data-bs-toggle="dropdown" aria-expanded="false">
                  < img src={btnCart} />
                  <span className="position-absolute top-0 start-100 translate-middle badge bg cart-badge">
                    {countItemsCart}
                    <span className="visually-hidden">unread messages</span>
                  </span>
                </button>
                <ul className="dropdown-menu dropdown-menu-lg-end dropdown-cart mt-4">
                  <li><p>Recently added products</p></li>
                  {
                    itemsCart.map((itemCartNav, i) => {
                      return (
                        <li key={itemCartNav.id} >
                          <div className='dropdown-li '>
                            <div className='row'>
                              <div className='col-md-12 d-inline-flex'>
                                <div className='col-md-2 mt-2'>
                                  <img src={itemCartNav.baseImageURL} />
                                </div>
                                <div className='col-md-7 mx-1 justify-content-center align-self-center'>
                                  <p className=''>{itemCartNav.name}</p>
                                </div>
                                <div className='col-md-3 mx-3 justify-content-center align-self-center color-price float-end'>
                                  <p className=' '>{itemCartNav.price}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                      )
                    })
                  }
                  <li><NavLink to={'/cart'}><button type="button" className="btn float-end btn-view-cart mt-3">View my Shopping Cart</button></NavLink></li>
                </ul>
              </div>
              {
                auth.authVerify()
                  ? <div className="btn-group nav-cart nav-a-btn mx-3">
                    <button type="button" className="btn position-relative btn-user" data-bs-toggle="dropdown" aria-expanded="false">
                      < img src={btnLogIn} />
                    </button>
                    <ul className="dropdown-menu dropdown-menu-lg-end dropdown-profile mt-4">
                      <li> <NavLink to={'/profile'} className=" nav-a-btn mx-4">Profile</NavLink></li>
                      <li> <NavLink to={'/my-orders'} className=" nav-a-btn mx-4">My Orders</NavLink></li>
                      <li> <NavLink className=" nav-a-btn mx-4" onClick={logOut}>Log Out</NavLink></li>
                    </ul>
                  </div>
                 :  <NavLink to={'/login'} className=" nav-a-btn mx-4"><img className='btn-login-nav' src={btnLogIn} /></NavLink>
              }
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  )
}
export { TodoMenu };
