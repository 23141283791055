import React, { useState } from 'react';

//btn svg
import facebook from '../../assets/images/auth/facebook.svg';
import instagram from '../../assets/images/auth/instagram.svg';
import linkedIn from '../../assets/images/auth/linkedIn.svg';

function FooterAuth() {

    return (
        <>
            <div className="container footer-auth">
                <footer className="row row-cols-1 row-cols-sm-2 row-cols-md-5 py-4 my-4">
                    <div className="">
                    </div>
                    <div className="col mb-4 row-footer mx-4">
                        <h6 className='title'>Customer service</h6>
                        <ul className="footer-column flex-column mt-3">
                            <li className=" mx-3"><a href="#" className="nav-link p-0 text-muted">Petitions, Complaints and Claims</a></li>
                            <li className=" mx-3"><a href="#" className="nav-link p-0 text-muted">Payment methods</a></li>
                            <li className=" mx-3"><a href="#" className="nav-link p-0 text-muted">Return and Refund</a></li>
                            <li className=" mx-3"><a href="#" className="nav-link p-0 text-muted">Warranty</a></li>
                            <li className=" mx-3"><a href="#" className="nav-link p-0 text-muted">contact us</a></li>
                        </ul>
                    </div>

                    <div className="col mb-4 row-footer  mx-4">
                        <h6 className='title'>About the store</h6>
                        <ul className="footer-column flex-column mt-3">
                            <li className=" mx-3"><a href="#" className="nav-link p-0 text-muted">About us</a></li>
                            <li className=" mx-3"><a href="#" className="nav-link p-0 text-muted">Lightning Deals</a></li>
                            <li className=" mx-3"><a href="#" className="nav-link p-0 text-muted">Shopee Policies</a></li>
                            <li className=" mx-3"><a href="#" className="nav-link p-0 text-muted">Terms of Service</a></li>
                            <li className=" mx-3"><a href="#" className="nav-link p-0 text-muted">Privacy Policy</a></li>
                            <li className=" mx-3"><a href="#" className="nav-link p-0 text-muted">Return and Refund Policy    </a></li>
                        </ul>
                    </div>

                    <div className="col mb-4 row-footer  mx-4">
                        <h6 className='title'>Follow Us</h6>
                        <ul className="footer-column flex-column mt-3">
                            <li className=" mx-3"><a href="#" className="nav-link p-0 text-muted"><img className='mx-1' src={facebook}/>Facebook</a></li>
                            <li className=" mx-3 mt-3"><a href="#" className="nav-link p-0 text-muted"><img className='mx-1 ' src={instagram}/>Instagram</a></li>
                            <li className=" mx-3 mt-3"><a href="#" className="nav-link p-0 text-muted"><img className='mx-1' src={linkedIn}/>LinkedIn</a></li>
       
                        </ul>
                    </div>
                </footer>
            </div>
        </>
    )
}
export { FooterAuth }