import React, { createContext, useState, useEffect } from 'react';
import { useLocalStorage } from './useLocalStorage';

//https://github.com/gitdagray/react_context
//https://gist.github.com/jimode/c1d2d4c1ab33ba1b7be8be8c50d64555

const cartStorage = createContext({});


export const CartProvider = ({ children }) => {

    const [order, setOrder] = useState([]);
    // const [saveCart, setsaveCart] = useState([]);

    const [totalProduct, setTotalProduct] = useState(0);

    const {
        item: cart,
        saveItem: saveItemsCart,
        error,
    } = useLocalStorage('CART', []);


    // Order - Local Storage: setting & getting data
    useEffect(() => {
        const order = JSON.parse(localStorage.getItem('CART'));
        if (order) {
            setOrder(order);
        } else {
            setOrder([]);
        }
    }, [])

    useEffect(() => {
        //localStorage.setItem('order', JSON.stringify(order));
    }, [order]);

    //save items cart
    const saveCart = (text) => {
        const storedUsers = localStorage.getItem('CART');
        const users = storedUsers ? JSON.parse(storedUsers) : [];
        const newUser = text;
        users.push(newUser);
        saveItemsCart(users);
    }
    const deleteTodo = (text) => {
        const localOrder = JSON.parse(localStorage.getItem('CART'));
        const todoIndex = localOrder.findIndex(todo => todo.text === text);
        if (text !== -1) {
            localOrder.splice(text, 1);
            saveItemsCart(localOrder);
        }
    };

    //get prices
    const prices= (text) => {
        let price = cart.map((item => {
            return item.price
        }))
        return (price);

    }

    const countItemsCart = cart.length;
    const itemsCart = cart;
    // setOrder(cart);
    // console.log(countItemsCart)

    return (
        <cartStorage.Provider value={{

            order, setOrder,
            countItemsCart,
            saveCart,
            deleteTodo,
            itemsCart,
            prices

        }}>
            {children}
        </cartStorage.Provider>
    )
}

export default cartStorage;
