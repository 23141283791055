import React, { useState, useEffect } from 'react';

//auth
import { useAuth } from "../../../context/AuthProvider.js";

//router dom
import { NavLink, useNavigate, Link, useLocation, useParams } from 'react-router-dom';

//get services api
import OrderService from "../../../services/order.service.js";

function Myorders() {
    //messages
    const [message, setMessage] = useState(null);

    //auth
    const auth = useAuth();

    //details order get
    const [orderDetails, setOrderDeatils] = useState([]);
    const orden = [];

    useEffect(() => {
        getOrder();

    }, []);

    //check BD order
    const getOrder = async (e) => {
        //auth.authToken();
        try {
            let bearer_Token = {
                'headers': {
                    'Authorization': `Bearer ${auth.authToken()}`
                }
            };
            //get order
            // console.log(auth.authId())
            const getOrderUser = await OrderService.getOrderByUser(auth.authId(), bearer_Token);
            //setOrderDeatils(getOrderUser.data);

            for (let index = 0; index < getOrderUser.data.length; index++) {
                const element = getOrderUser.data[index];
                let orderDetailParse = JSON.parse(element.order_details);
                //console.log(orderDetailParse)
                let arrOrders = {
                    id: element.id,
                    createtime: element.create_time,
                    total: element.total_order,
                    details: orderDetailParse
                }
                //orderDetails.push(arrOrders)
                setOrderDeatils(prevOrders => [...prevOrders, arrOrders]);
            }
            //console.log(orderDetails)
        } catch (error) {
            //error message
        }
    }
    //pagination
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 6;
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = orderDetails.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(orderDetails.length / itemsPerPage);
    const handleClick = (e, pageNumber) => {
        e.preventDefault();
        setCurrentPage(pageNumber);
    };
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
    }
    const handlePrevClick = () => {
        setCurrentPage(currentPage - 1);
    };

    const handleNextClick = () => {
        setCurrentPage(currentPage + 1);
    };
    const prevButtonDisabled = currentPage === 1;
    const nextButtonDisabled = currentPage === Math.ceil(orderDetails.length / itemsPerPage);
     console.log(prevButtonDisabled);
    //end pagination

    return (
        <>
            <section className='cart'>
                <div className='header mt-4'>
                    <h1 className='text-center'>My <b>Orders</b></h1>
                    {message ?
                        <div className={`alert aletr-auth-login alert-${message.error ? 'danger' : 'success'}`} role="alert">
                            <strong>{message.msg}</strong>
                        </div>
                        : null
                    }
                </div>
                <div className='container'>
                    {currentItems.map((data, i) => {
                        const dateObj = new Date(data.createtime);
                        const formattedDate = dateObj.toLocaleDateString('en-US', {
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric'
                        });
                        return (
                            <div className='row' key={i}  >
                                <div className='col-md-12 cart-content mt-4'>
                                    <div className='col-md-12 d-flex mt-3 header-cart '>
                                        <p className='mx-2'><b>Order Nº </b>{data.id}</p>
                                        <p className='mx-2'><b>Buy up Date: </b>{formattedDate}</p>
                                    </div>
                                    <div className='col-md-12 d-flex align-items-center justify-content-center mt-1 mb-4 header-cart '>
                                        {data.details.map((details, j) => {
                                            return (
                                                <div className='col-md-1 d-flex' key={j}>
                                                    <div className='col-md-12'>
                                                        <img className='img-product-cart ' src={details.price_data.product_data.images} />
                                                    </div>
                                                </div>
                                            )
                                        })
                                        }
                                        <div className='col-md-1 price-product-color text-center ' >
                                            <p><b>Total: </b>${data.total}</p>
                                        </div>
                                        <div className='col-md-4 ms-auto p-2'>
                                            <NavLink to={`/voucher/${data.id}`} className='w-50 btn btn-checkout'> View Details</NavLink >
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    )}
                </div>



            </section>
            <section className='cart'>
                <div className='container'>
                    <div className='row'>
                        <nav aria-label="Page navigation ">
                            <ul className="pagination justify-content-center">
                                <li className={prevButtonDisabled ? 'disabled' : ''} ><a onClick={handlePrevClick} className="page-link">Previous</a></li>
                                {pageNumbers.map(number => (
                                    <li className="page-item" key={number}>
                                        <a className="page-link" href="#" onClick={e => handleClick(e, number)}>
                                            {number}
                                        </a>
                                    </li>
                                ))}
                                <li className={nextButtonDisabled ? 'disabled' : ''}><a onClick={handleNextClick} className="page-link">Next</a></li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </section>
        </>
    )

}
export { Myorders }