import React, { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate, NavLink, Navigate } from 'react-router-dom'

//footer
import { FooterAuth } from '../../layouts/footerAuth'

//svg
import imgWhatsapp from '../../../assets/images/auth/whatsapp.svg';
import { Form, Button } from 'react-bootstrap';

function VerifyCode() {

    const [code, setCode] = useState(['', '', '', '', '', '']);
    const inputRefs = useRef([]);
    const handleInputChange = (index, event) => {
        const value = event.target.value;
        if (!isNaN(value) && value !== '') {
            const newCode = [...code];
            newCode[index] = value;
            setCode(newCode);
            if (value !== '') {
                if (index < inputRefs.current.length - 1) {
                    inputRefs.current[index + 1].focus();
                }
            } else if (index > 0) {
                inputRefs.current[index - 1].focus();
            }
        }
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        console.log('Verification code submitted: ', code.join(''));
    };

    return (
        <>
            <div className=' verify-code'>
                <div className='container row-down ' >
                    <div className='row'>
                        <div className='col-md-12 mt-5 pb-20'>
                            <div className='col-md-12 d-flex align-items-center justify-content-center'>
                                <div className="text-center mx-5">
                                    <button className="btn btn-step rounded-circle btn-lg">
                                        1
                                    </button>
                                    <div className='subtitle mt-2'>Check the Number</div>
                                </div>
                                <div className="text-center mx-5">
                                    <button className="btn btn-step rounded-circle btn-lg">
                                        2
                                    </button>
                                    <div className='subtitle mt-2'>Create a Password</div>
                                </div>
                                <div className="text-center mx-5">
                                    <button className="btn btn-step rounded-circle btn-lg">
                                        3
                                    </button>
                                    <div className='subtitle mt-2'>Threw out</div>
                                </div>

                            </div>
                            <div className='col-md-12 d-flex align-items-center justify-content-center mt-5'>
                                <div className='col-md-4 enter-verification-code text-center '>
                                    <p>Enter the verificaction code</p>
                                    <p>Your verification code is sent by WhatsApp to <img src={imgWhatsapp} /> +573104339539</p>

                                    <div className="row justify-content-center">
                                        <div className="col-lg-12 col-md-12 col-sm-10 col-12">
                                            <Form onSubmit={handleSubmit}>
                                                <Form.Group className="d-flex justify-content-center">
                                                    {code.map((value, index) => (
                                                        <Form.Control
                                                            key={index}
                                                            type="text"
                                                            className="text-center mx-2 input-code"
                                                            maxLength="1"
                                                            pattern="\d"
                                                            value={value}
                                                            onChange={(event) => handleInputChange(index, event)}
                                                            ref={(el) => (inputRefs.current[index] = el)}
                                                            required
                                                        />
                                                    ))}
                                                </Form.Group>
                                                <div className='mt-5'>
                                                    <p>Dind't receive the code?</p>
                                                    <p><NavLink>Resend or try a different verification method</NavLink></p>
                                                </div>

                                                <Form.Group className="text-center">
                                                    <Button type="submit" className='mt-4 btn-verify'>
                                                        VERIFY
                                                    </Button>
                                                </Form.Group>
                                            </Form>
                                        </div>
                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>
                </div>
            </div>
            <FooterAuth />
        </>
    )
}
export { VerifyCode };