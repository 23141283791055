import React, { useContext, useState } from "react";

import http from "../configs/http.config";

class StripeService {

    sendCheckOut(body) {
        return http.post(`/stripe/create-checkout-session`,body);
    }

 
}
export default new StripeService();