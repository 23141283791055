import React, { useState, useEffect } from 'react';
import { useAuth } from '../../../context/AuthProvider.js';
import { NavLink } from 'react-router-dom'

//formik
import { Field, useFormik, Formik } from "formik";
import * as Yup from "yup";

//auth import
import UserService from '../../../services/user.service.js'

//bootstrap
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';

//validation
const validationSchema = Yup.object().shape({
    userName: Yup.string()
        .required("Required"),
    name: Yup.string()
        .required("Required"),
    lastName: Yup.string()
        .required("Required"),
    email: Yup.string()
        .email()
        .required("Required"),
    phone: Yup.number()

        .required("Required")
});


function Profile() {
    const imgProfile = require('../../../assets/images/profile/profile.png')


    //validation auth
    const auth = useAuth();
    //message error
    const [message, setMessage] = useState(null);
    //alert
    const [showAlert, setShowAlert] = useState(true);

    const [userDatos, setUserDatos] = useState('');

    //avatar
    const [photo, setPhoto] = useState(null);
    const [avatarProfile, setAvatarProfile] = useState('');


    useEffect(() => {
        onSubmitGet()
    }, []);

    const onSubmitGet = async () => {
        let bearer_Token = {
            'headers': {
                'Authorization': `Bearer ${auth.authToken()}`
            }
        };
        try {
            const getAccount = await UserService.getAccount(auth.authId(), bearer_Token);
            //console.log(getAccount.data.user)
            if (getAccount.status === 200 && getAccount.data.statusCode === 200) {
                setAvatarProfile(getAccount.data.user.avatar);

                formik.setValues({
                    userName: getAccount.data.user.username ? getAccount.data.user.username : '',
                    name: getAccount.data.user.name ? getAccount.data.user.name : '',
                    lastName: getAccount.data.user.last_name ? getAccount.data.user.last_name : '',
                    email: auth.authEmail(),
                    phone: getAccount.data.user.phone ? getAccount.data.user.phone : '',
                    aboutMe: getAccount.data.user.about_me ? getAccount.data.user.about_me : '',
                    gender: getAccount.data.user.gender ? getAccount.data.user.gender : '',
                });
            } else {
                setMessage({ error: true, msg: 'An error has occurred! try again' });
            }
        } catch (error) {
            setMessage({ error: true, msg: 'An error has occurred! try again' });
        }
    }

    //send update data account
    const onSubmit = async (values) => {
        let arrData = {
            username: values.userName,
            name: values.name,
            last_name: values.lastName,
            email: values.email,
            about_me: values.aboutMe,
            gender: values.gender,
            phone: values.phone
        }
        //console.log(arrData)
        let bearer_Token = {
            'headers': {
                'Authorization': `Bearer ${auth.authToken()}`
            }
        };

        try {
            const updateAccount = await UserService.updateAccount(auth.authId(), arrData, bearer_Token);
            //console.log(updateAccount)
            if (updateAccount.status === 200 && updateAccount.data.statusCode === 200) {
                setMessage({ error: false, msg: 'Update completed!' });

            } else {
                setMessage({ error: true, msg: 'An error has occurred! try again' });
            }
        } catch (error) {
            setMessage({ error: true, msg: 'An error has occurred! try again' });
        }
    }

    //formik
    const formik = useFormik({
        initialValues: { userName: "", name: "", lastName: "", phone: "", email: "", aboutMe: "", gender: "" },
        validateOnBlur: true,
        onSubmit,
        validationSchema: validationSchema,

    });

    //avatar
    const handlePhotoSelect = (event) => {
        setPhoto(event.target.files[0]);
         
         const file = event.target.files[0];         
         if (file && !/^(image\/jpeg|image\/png)$/.test(file.type)) {
           setPhoto('');
           setMessage({ error: true, msg: 'The file must be of type PNG or JPG' });
           return;
         }

         const maxSize = 1 * 1024 * 1024; // 5 MB
         if (file && file.size > maxSize) {
           setPhoto('');
           setMessage({ error: true, msg: `The file must be less than or equal to  ${maxSize / 1024 / 1024} MB` });
           return;
         }
    }
    //submit avatar
    const handleSubmitAvatar = async (event) => {
        const formData = new FormData();
        formData.append('avatar', photo);

        console.log(formData)
        if (photo) {
            try {
                let bearer_Token = {
                    'headers': {
                        'Authorization': `Bearer ${auth.authToken()}`
                    }
                };
                const uploadAvatar = await UserService.updateAvatar(auth.authId(), formData,bearer_Token);
                //console.log(uploadAvatar)
                if (uploadAvatar.status === 200 && uploadAvatar.data.statusCode === 200) {
                    setMessage({ error: false, msg: 'Avatar uploaded successfully' });
                    onSubmitGet();
                }else{
                    setMessage({ error: true, msg: 'An error has occurred! try again' });
                }
            } catch (error) {
                setMessage({ error: true, msg: 'An error has occurred! try again' });
            }
        } else {
            setMessage({ error: true, msg: 'please select an avatar' });
        }
    }

    return (
        <>
            <section>
                <section className='profile'>
                    <div className='header mt-4'>
                        <h1 className='text-center'>My <b>Profile</b></h1>
                    </div>
                    <div className='container profile-content'>
                        <div className='row'>
                            <div className="col-lg-3 col-md-12  col-sm-12 col-12">
                                <div className="text-center mb-4">
                                    <img src={avatarProfile? avatarProfile : imgProfile } className="avatar img-circle img-thumbnail" alt="avatar" />
                                    <h6>Upload Avatar...</h6>
                                    <form onSubmit={handleSubmitAvatar}>
                                        <input className="text-center center-block file-upload mt-3" type="file" onChange={handlePhotoSelect} />
                                        <button className="w-50 btn btn-lg btn-login mt-3 " type="submit">Upload</button>
                                    </form>
                                </div>

                                <ul className="list-group">
                                    <li className="list-group-item text-muted">Activity <i className="fa fa-dashboard fa-1x"></i></li>
                                    <li className="list-group-item text-right"><span className="pull-left"><strong>Orders</strong></span> 0</li>
                                </ul>
                            </div>

                            <div className="col-lg-9 col-md-12  col-sm-12 col-12 profile-account">
                                {message ?
                                    <Alert show={showAlert} variant={`${message.error ? 'danger' : 'success'}`}>
                                        <p>{message.msg}</p>
                                        <hr />
                                        <div className="d-flex justify-content-end">
                                            <Button onClick={() => setShowAlert(false)} size="sm" variant={`outline-${message.error ? 'danger' : 'success'}`}>
                                                Close
                                            </Button>
                                        </div>
                                    </Alert>
                                    : null
                                }

                                <form className=" needs-validation" onSubmit={formik.handleSubmit}>
                                    <div className='row'>
                                        <div className="col-lg-6 col-md-12  col-sm-12 col-12">
                                            <div className='mb-3'>
                                                <label className="form-label">User Name *</label>
                                                <input type="text" name='userName' className="form-control" id="userName"
                                                    value={formik.values.userName}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur} />
                                                {formik.errors.userName && formik.touched.userName && (
                                                    <div className="form-text login-create-help text-danger">{formik.errors.userName}</div>
                                                )}
                                            </div>
                                            <div className='mb-3'>
                                                <label className="form-label">Name *</label>
                                                <input type="text" className="form-control" name='name' id="name"
                                                    value={formik.values.name}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur} />
                                                {formik.errors.name && formik.touched.name && (
                                                    <div className="form-text login-create-help text-danger">{formik.errors.name}</div>
                                                )}

                                            </div>
                                            <div className='mb-3'>
                                                <label className="form-label">Last Name *</label>
                                                <input type="texxt" className="form-control" id="lastName" name='lastName'
                                                    value={formik.values.lastName}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur} />
                                                {formik.errors.name && formik.touched.name && (
                                                    <div className="form-text login-create-help text-danger">{formik.errors.name}</div>
                                                )}
                                            </div>
                                            <div className='mb-3'>
                                                <label className="form-label">Email*</label>
                                                <input type="email" disabled className="form-control" name='email' id="email"
                                                    value={formik.values.email}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur} />
                                                {formik.errors.email && formik.touched.email && (
                                                    <div className="form-text login-create-help text-danger">{formik.errors.email}</div>
                                                )}
                                            </div>
                                            <div className='mb-3'>
                                                <label className="form-label">Phone *</label>
                                                <input type="number" className="form-control" id="phone" name='phone'
                                                    value={formik.values.phone}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur} />
                                                {formik.errors.phone && formik.touched.phone && (
                                                    <div className="form-text login-create-help text-danger">{formik.errors.phone}</div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-12  col-sm-12 col-12">
                                            <div className='mb-3'>
                                                <label className="form-label">About Me</label>
                                                <textarea className="form-control" placeholder="Description about me" id="aboutMe" name='aboutMe'
                                                    value={formik.values.aboutMe}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur} ></textarea>
                                            </div>
                                            <div className='mb-3'>
                                                <label className="form-label">Gender</label>
                                                <select className="form-select" id='gender' name='gender'
                                                    value={formik.values.gender}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                >
                                                    <option value="">Select an option</option>
                                                    <option value="female">Female</option>
                                                    <option value="male">Male</option>
                                                    <option value="other">Other</option>
                                                </select>
                                            </div>
                                        </div>
                                        <button disabled={!formik.isValid} className="w-25 btn btn-lg btn-login mt-3 " type="submit">Update Account</button>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>
                </section>
            </section>
        </>

    )
}
export { Profile }