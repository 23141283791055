import React, { useState, useEffect, useContext } from 'react';
import productService from "../../../services/product.service";
import { Link, useParams, useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

//bootstrap
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';

//localstorage
import CartProvider from '../../../context/cartLocalStorage';

function StepProduct() {
    const { product_id } = useParams();
    const [product, setProduct] = useState([]);
    const [steps, setSteps] = useState([]);
    const [placeholder, setPlaceHolder] = useState(false);
    const [items, setItems] = useState([]);
    const [baseImage, setBaseImage] = useState('');
    const [descriptionProduct, setDescriptionProduct] = useState('');

      //naviagte
      const navigate = useNavigate();


    //message alerts
    //message 
    const [message, setMessage] = useState(null);
    //alert
    const [showAlert, setShowAlert] = useState(true);

    //CHECK PARENT ITEMS
    const [imageTitle, setImageTitle] = useState('');
    const [radioSelect, setRadioSelect] = useState('');
    const [radioSelectOn, setRadioSelectOn] = useState(true);
    //select image title 
    const [selectOptionSelect, setSelectOptionSelect] = useState('');
    const [selectOptionSelectOn, setelectOptionSelectOn] = useState(true);

    //select image
    const [selectedImages, setSelectedImages] = useState([]);
    const [selectVariations, setSelectVariatios] = useState([]);

    const [priceTotal, setPriceTotal] = useState('');

    // cart
    const { saveCart } = useContext(CartProvider);

    //next step and back step
    const [nextStep, setNextStep] = useState(false);
    const [idNextStep, setIdNextStep] = useState(0);



    useEffect(() => {
        getProduct();
        handleImageClick()
    }, [radioSelect, selectOptionSelect, radioSelectOn, selectVariations, nextStep, idNextStep]);

    //get product steps an intems BD
    const getProduct = async (e) => {
        try {
            const getProduct = await productService.getProductDetail(product_id);

            //console.log(getProduct.data.product)
            if (getProduct.status === 200) {
                //get array step + items
                let stepsResponse = [];
                let itemsResponse = [];
                for (let index = 0; index < getProduct.data.getStepbyId.length; index++) {
                    const element = getProduct.data.getStepbyId[index];
                    //console.log(element)
                    stepsResponse.push(element);
                    itemsResponse.push(element.items);
                } //end step+items
                setProduct(getProduct.data.product);//set product
                setSteps(stepsResponse); // steps by product
                setItems(itemsResponse); // set items by step
                // handleImageClick(getProduct.data.product.imageURL)
                setBaseImage(getProduct.data.product.imageURL);// base image url
                setDescriptionProduct(getProduct.data.product.description);
                //laod first acordion
                setNextStep(true)

                setPlaceHolder(true);
            }
        } catch (error) {
            setPlaceHolder(false);
        }
    }

    //Save data product selected to local storage
    const onSearchValueChange = (e) => {
        if (selectVariations.length > 0) {
            let item_options = [];
            const images = [];

            for (let index = 0; index < selectVariations.length; index++) {
                const element = selectVariations[index];
                images.push(element.item_image);
                item_options.push(element.item_name);
            }

            const dataUpdate = {
                id: uuidv4(),
                id_product: product.id,
                name: product.name,
                baseImageURL: product.imageURL,
                description: product.description,
                options: item_options.join(", "),
                price: priceTotal
            };
            //console.log(dataUpdate)
            saveCart(dataUpdate);
            setSelectVariatios([]);
            navigate('/cart');

        } else {
            handleShow();
            setMessage({ error: true, msg: "Please select a options!" });
        }

    };

    //select  items and add array to proces
    const onSelectItems = (id_item, id_step, item_name, item_price_increase, image, item_is_required, item_option_type) => {
        if (selectVariations.length === 0) {//array is null create array item
            let selected = {
                id_step: id_step,
                id_item: id_item,
                item_name: item_name,
                item_price_increase: item_price_increase,
                item_image: image,
                item_is_required: item_is_required,
                item_option_type: item_option_type
            }
            let newSelectVariations = [...selectVariations, selected];
            setSelectVariatios(newSelectVariations);
            return
        } else {
            for (let index = 0; index < selectVariations.length; index++) { //for to search array selected
                const element = selectVariations[index];
                if (element.id_step === id_step) {//get steps becouse  check steps and doesnt duplicate
                    if (item_is_required === 1) { //check item required radio button
                        const selectedTest = {
                            id_step: id_step,
                            id_item: id_item,
                            item_name: item_name,
                            item_price_increase: item_price_increase,
                            item_image: image,
                            item_is_required: item_is_required,
                            item_option_type: item_option_type
                        };
                        //clear array
                        setSelectVariatios([]);

                        //add new array to selected
                        setSelectVariatios(prevState => {
                            let newSelectVariations = [...prevState, selectedTest];
                            return newSelectVariations;
                        });
                        return;
                    }

                    //next condition is step already exist after delete step and add new step 
                    const newSelectVariations = selectVariations.filter(item => item !== element);
                    //create new array before deletet and create new
                    const selected = {
                        id_step: id_step,
                        id_item: id_item,
                        item_name: item_name,
                        item_price_increase: item_price_increase,
                        item_image: image,
                        item_is_required: item_is_required,
                        item_option_type: item_option_type
                    };
                    //add new array
                    const updatedSelectVariations = [...newSelectVariations, selected];
                    setSelectVariatios(updatedSelectVariations);
                    return;
                } else {
                    //does not completing conditions and add item to array
                    let selected = {
                        id_step: id_step,
                        id_item: id_item,
                        item_name: item_name,
                        item_price_increase: item_price_increase,
                        item_image: image,
                        item_option_type: item_option_type
                    }
                    let newSelectVariations = [...selectVariations, selected];
                    setSelectVariatios(newSelectVariations);
                    return;
                }
            }
        }

    }

    //select radio
    const onSelectTRadio = (id_item, id_step, item_name, item_price_increase, image, item_is_required, item_option_type) => {
        setRadioSelect(id_item);
        setRadioSelectOn(false);
        onSelectItems(id_item, id_step, item_name, item_price_increase, image, item_is_required, item_option_type)

    }
     //select titleImage
    const onSelectTitleImage = (id_item, id_step, item_name, item_price_increase, image, item_is_required, item_option_type) => {
     
        onSelectItems(id_item, id_step, item_name, item_price_increase, image, item_is_required, item_option_type)

    }

    //select option select
    const onSelectSelect = (id_item, id_step, item_name, item_price_increase, image, item_is_required, item_option_type) => {
        setSelectOptionSelect(id_item);
        // selectOptionSelectOn(false);
        onSelectItems(id_item, id_step, item_name, item_price_increase, image, item_is_required, item_option_type)
    }

    const handleImageClick = () => {
        // After add items product to array, procces product in for before save in data storage
        let price = 0;
        for (let index = 0; index < selectVariations.length; index++) {
            const element = selectVariations[index];
            let numPrice = parseFloat(element.item_price_increase);
            price += numPrice;
        }
        const sum = price;
        const roundedSum = sum.toFixed(2);
        setPriceTotal(roundedSum)

    };

    //next step
    const onNextStep = (step_id, id) => {
        const numero = parseInt(id, 10);
        let nextSum = 0;
        if (id === 0) {
            nextSum = 1
        } else {
            nextSum = numero + 1;
        }
        setNextStep(true)
        setIdNextStep(nextSum);
    }
    //back step
    const onBackStep = (step_id, id) => {
        const numero = parseInt(id, 10);
        let nextSum = 0;
        if (id === 0) {
            nextSum = 1
        } else {
            nextSum = numero - 1;
        }
        setNextStep(true)
        setIdNextStep(nextSum);
        console.log(nextStep, idNextStep)
    }

    //modal config
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>{
            !placeholder
                ? <section className='step-product placeholder-glow'>
                    <div className='step-product-backgound mt-4'>
                        <h1 className=' text-center '><p className='placeholder w-25'></p></h1>
                    </div>
                    <div className='container mt-5'>
                        <div className='row'>
                            <div className='col-md-4'>
                                <img className='image-product-img placeholder' />
                            </div>
                            <div className='col-md-8'>
                                < div className="accordion" >
                                    <div className="accordion-item">
                                        <div className="accordion-header" id="headingTwo">
                                            <div className='step-header-acordeon col-12'>
                                                <div className='col-2 step-header-acordeon-title'>
                                                    <div className='my-auto'><h6 className='mt-2 mx-3 placeholder w-75'> </h6></div>
                                                </div>
                                                <div className='col-10 step-header-acordeon-subtitle'>
                                                    <button className="accordion-button collapsed disabled placeholder col-4" aria-hidden="true" type="button">
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-collapse collapse show" >
                                            <div className="accordion-body">
                                                <strong className='placeholder w-75'></strong>
                                                <div className='step-description placeholder ' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className=' step-price mt-5'>
                        <div className='container '>
                            <div className='float-end mx-5 mt-1 step-price-flex placeholder '><p className='w-75'></p><button className='btn mx-5 disabled placeholder col-4 aria-hidden="true"'></button></div>
                        </div>
                    </div>
                </section>

                : <section className='step-product'>
                    <div className='step-product-backgound mt-4'>
                        <h1 className='text-center'>{product.name}</h1>
                    </div>
                    <div className='container mt-5'>
                        <div className='row'>
                            <div className='col-md-6 center-product d-flex justify-content-center'>
                                {selectVariations.length > 0
                                    ? selectVariations.map((imageUrl, index) => (
                                        <img
                                            key={index}
                                            src={imageUrl.item_image}
                                            className="image-product-img"
                                            style={{ position: "absolute" }}
                                        />
                                    ))
                                    : <img src={baseImage} className="image-product-img" style={{ position: "absolute" }} />
                                }
                            </div>
                            <div className='col-md-6 overflow-scroll overflow-steps'>
                                {
                                    steps.map((step, s) => {
                                        return (
                                            < div className="accordion accordion-container" id={`acordeon${step.id}`} key={step.id}>
                                                <div className="accordion-item">
                                                    <div className="accordion-header" id="headingTwo">
                                                        <div className='step-header-acordeon col-12'>
                                                            <div className='col-2 step-header-acordeon-title'>
                                                                <div className='my-auto'><h6 className='mt-2 mx-3'>STEP  </h6></div>
                                                            </div>
                                                            <div className='col-10 step-header-acordeon-subtitle'>
                                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${step.id}`} aria-expanded="false" aria-controls="">
                                                                    {step.step_title}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div id={`${idNextStep === s && nextStep ? 'collapse${step.id}' : 'true'}  `} className={`accordion-collapse collapse ${idNextStep === s && nextStep ? 'show' : 'true'} `} aria-labelledby="headingTwo" data-bs-parent={`#acordeon${step.id}`}>
                                                        <div className="accordion-body">
                                                            <strong >{step.step_subtitle}</strong>
                                                            <div className='container mt-3'>
                                                                <div className='row'>
                                                                    {step.items.map((item, index) => {
                                                                        return (
                                                                            <div className={item.item_type === 'radio' ? 'col text-center'
                                                                                : item.item_type === 'imageAndTitle' ? 'col text-center'
                                                                                    : item.item_type === 'select' ? 'col-md-1 d-flex justify-content-start' : ''} key={item.id}>
                                                                                <div className=''>
                                                                                    {item.item_type === 'radio'
                                                                                        ? <div>
                                                                                            <img className='mt-3 ' src={item.item_image_placeholder} style={{ width: '70px' }} />
                                                                                            <div className="d-flex justify-content-center form-check">
                                                                                                <input onClick={() => onSelectTRadio(item.id, item.step_id, item.item_name, item.item_increase_price, item.item_image, item.item_is_required, item.item_type)} className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="option1" />
                                                                                            </div>
                                                                                        </div>
                                                                                        : item.item_type === 'imageAndTitle'
                                                                                            ? <div>
                                                                                                {radioSelect === item.item_parent_id
                                                                                                    ?
                                                                                                    <div className=''>
                                                                                                        <img onClick={() => onSelectTitleImage(item.id, item.step_id, item.item_name, item.item_increase_price, item.item_image, item.item_is_required, item.item_type)} className={` mt-3 ${imageTitle === item.id ? 'border border-1 border-info' : ''}`} src={item.item_image_placeholder} style={{ width: '70px' }} />
                                                                                                        <div className="d-flex justify-content-center">
                                                                                                            <p>{item.item_name}</p>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    : radioSelectOn
                                                                                                        ?
                                                                                                        <div className=''>
                                                                                                            <img onClick={() => onSelectTitleImage(item.id, item.step_id, item.item_name, item.item_increase_price, item.item_image, item.item_is_required, item.item_type)} className={` mt-3 ${imageTitle === item.id ? 'border border-1 border-info' : ''}`} src={item.item_image_placeholder} style={{ width: '70px' }} />
                                                                                                            <div className="d-flex justify-content-center">
                                                                                                                <p>{item.item_name}</p>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        : <></>
                                                                                                }
                                                                                            </div>
                                                                                            : item.item_type === 'select'
                                                                                                ? selectVariations.map((variations, v) => {
                                                                                                    return (
                                                                                                        < div key={v}>
                                                                                                            {
                                                                                                                variations.item_option_type === 'imageAndTitle' && item.item_parent_id === variations.id_item
                                                                                                                    ? <div className=''>
                                                                                                                        <div className="d-flex justify-content-center">
                                                                                                                            <p onClick={() => onSelectSelect(item.id, item.step_id, item.item_name, item.item_increase_price, item.item_image, item.item_is_required, item.item_type)} className='border p-2'>{item.item_name}</p>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    : <></>
                                                                                                            }
                                                                                                        </ div>
                                                                                                    )
                                                                                                })
                                                                                                : <></>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    })}
                                                                </div>
                                                            </div>

                                                            <div className='step-description ' dangerouslySetInnerHTML={{ __html: step.step_note }} />
                                                        </div>
                                                        {steps.length-1 === 0 && idNextStep === 0
                                                            ? <></>

                                                            : idNextStep === 0
                                                                ? <div className='ms-3 mb-2'>
                                                                    <span onClick={() => onNextStep(step.id, s)} className="mx-2 btn badge text-bg-primary">Next <i className="fa-solid fa-arrow-right"></i></span>
                                                                </div>
                                                                : idNextStep === steps.length - 1
                                                                    ? <div className='ms-3 mb-2'>
                                                                        <span onClick={() => onBackStep(step.id, s)} className="mx-2 btn badge text-bg-primary"> <i className="fa-solid fa-arrow-left"></i> Back</span>
                                                                    </div>
                                                                    : <div className='ms-3 mb-2'>
                                                                        <span onClick={() => onBackStep(step.id, s)} className="mx-2 btn badge text-bg-primary"> <i className="fa-solid fa-arrow-left"></i> Back </span>
                                                                        <span onClick={() => onNextStep(step.id, s)} className="btn badge text-bg-primary">Next <i className="fa-solid fa-arrow-right"></i></span>
                                                                    </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                    )
                                }
                            </div>

                        </div>
                    </div>
                    <div className=' step-price mt-5 '>
                        <div className='container '>
                            <div className='float-end mx-5 mt-1 step-price-flex'>
                                <p>TOTAL$ {priceTotal}</p><button className='btn mx-5' onClick={onSearchValueChange} >ADD TO CART</button></div>
                        </div>
                    </div>
                </section>
        }

            {/* modal */}
            <Modal show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                centered className=' modal-login'>
                <div className="modal-content content">
                    <div className="modal-body">
                        {message ?
                            <div className={` text-center alert alert-${message.error ? 'danger' : 'success'}`} role="alert">
                                <strong>{message.msg}</strong>
                            </div>
                            : null
                        }                    </div>
                    <div className="modal-footer footer-login-modal  d-md-block  text-center">
                        <button type="button" className="btn btn-cancel " onClick={handleClose}>Close</button>
                    </div>
                </div>
            </Modal>
        </>
    )
}
export { StepProduct };

