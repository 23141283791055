import React, { useContext, useState } from "react";

import http from "../configs/http.config";

class ProductService {

    getProducts() {
        return http.get(`/products/`);
    }

    getProductDetail(product_id) {
        return http.get(`/products/${product_id}`);
    }

}
export default new ProductService();