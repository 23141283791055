import React from 'react';
import { HashRouter, Route, Routes } from 'react-router-dom'

import './App.css';

import { CartProvider } from './context/cartLocalStorage';
//import { DataProvider } from './context/DataContext';
import { AuthProvider, AuthRoute, PrivateRoute } from './context/AuthProvider.js'



//pages layout
import { TodoMenu } from './components/layouts/menu';
import { Footer } from './components/layouts/footer';
import { Home } from './components/pages/home/home';
import { Layout } from './components/layouts/layout';

//cart
import { StepProduct } from './components/pages/products/step-product';
import { Cart } from './components/pages/cart/cart';
import { Voucher } from './components/pages/cart/voucher';
import { Myorders } from './components/pages/cart/my-orders';
//auth
import { LogIn } from './components/pages/auth/login'
import { SignUp } from './components/pages/auth/signup'
import { VerifyCode } from './components/pages/auth/verifyCode'
import { Recovery } from './components/pages/auth/recovery'
import { ResetPassword } from './components/pages/auth/password'
import { CreateAccount } from './components/pages/auth/create-account'

//profile
import { Profile} from './components/pages/profile/profile'

function App() {
  return (
    <>
      <HashRouter>
      <AuthProvider>
        <CartProvider> <TodoMenu /> </CartProvider>
        <Routes>
          <Route path='/*' element={<Home />}></Route>
         {/*  <Route path='/*' element={<AuthRoute><Home /></AuthRoute>}></Route> */}
          <Route path="/product/step-product/:product_id" element={<CartProvider><StepProduct /></CartProvider>} />
          <Route path='/cart' element={<CartProvider><Cart /></CartProvider>} />
          <Route path='/login' element={<LogIn />}></Route>
          <Route path='/signup' element={<SignUp />}></Route>
          <Route path='/create-account/:email' element={<CreateAccount />}></Route>
          <Route path='/verifycode' element={<VerifyCode />}></Route>
          <Route path='/recovery' element={<Recovery />}></Route>
          <Route path='/password/:token' element={<ResetPassword />}></Route>
          <Route path='/profile' element={<AuthRoute><Profile/></AuthRoute>} > </Route>
          <Route path='/voucher/:id' element={<AuthRoute><Voucher/></AuthRoute>} > </Route>
          <Route path='/my-orders' element={<AuthRoute><Myorders/></AuthRoute>} > </Route>
        </Routes>
        <Footer />
        </AuthProvider>
      </HashRouter>

    </>
  );
}

export default App;