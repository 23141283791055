import React, { useState, useEffect } from 'react';

//auth
import { useAuth } from "../../../context/AuthProvider.js";

//router dom
import { NavLink, useNavigate, Link, useLocation, useParams } from 'react-router-dom';

//get services api
import OrderService from "../../../services/order.service.js";

import queryString from 'query-string';
import orderService from '../../../services/order.service.js';
function Voucher() {
    //use history router
    const navigate = useNavigate();
    const location = useLocation();
    const { id } = useParams();

    //auth
    const auth = useAuth();

    //url
    const parsed = queryString.parse(location.search);

    //details order get
    const [orderDetails, setOrderDeatils] = useState([]);
    const [orderStripe, setOrderStripe] = useState([]);
    const [orderStripeCheck, setOrderStripeCheck] = useState(false);

    //message stripe
    const [message, setMessage] = useState(null);
    useEffect(() => {
        getOrder();
        //check url after stripe and search id order
        const parsed = queryString.parse(location.search);
        if (parsed.success) {
            setMessage({ error: false, msg: "Order placed! You will receive an email confirmation." });
            localStorage.removeItem('CART');
        }
        if (parsed.canceled) {
            setMessage({ error: false, msg: "Order canceled -- continue to shop around and checkout when you're ready." });
        }
        

    }, []);
    //validation auth
    const toggleLogOut = () => {
        auth.logout();
    };

    //check BD order
    const getOrder = async (e) => {
        //auth.authToken();
        
        try {
            let bearer_Token = {
                'headers': {
                    'Authorization': `Bearer ${auth.authToken()}`
                }
            };

            //get order
            //console.log(id)
            const getOrderId = await orderService.getOrderById(id, bearer_Token);
            //console.log(getOrderId)
            if (getOrderId.status === 200 && getOrderId.data.id.toString() === id) {
                //get and parse string
                let orderDetailsParse = JSON.parse(getOrderId.data.order_details);
                let orderStripeParse = JSON.parse(getOrderId.data.stripe_response);
                setOrderDeatils(orderDetailsParse);
                setOrderStripe([orderStripeParse]);
               
                if (getOrderId.data.stripe_response===null) {
                    setOrderStripeCheck(false)
                } else {
                    setOrderStripeCheck(true)
                }
                //console.log(orderDetailsParse)

            } else if (getOrderId.data.statusCode === 404) {
                console.log('not found')
            }
        } catch (error) {
            //error message
        }


    }
    return (
        <>
            <section className='cart'>
                <div className='header mt-4'>
                    <h1 className='text-center'>Shopping <b>Invoice #{id}</b></h1>
                    {message ?
                        <div className={`alert aletr-auth-login alert-${message.error ? 'danger' : 'success'}`} role="alert">
                            <strong>{message.msg}</strong>
                        </div>
                        : null
                    }
                </div>
                <div className='container'>
                    {orderDetails.map((data, i) => {
                        return (
                            <div className='row' key={i} >
                                <div className='col-md-12 cart-content mt-4'>
                                    <div className='col-md-12 d-flex mt-3 header-cart '>
                                        {//console.log(data.price_data)
                                        }
                                        <div className='col-md-3'>
                                            <img className='img-product-cart ' src={data.price_data.product_data.images} />
                                        </div>
                                        <div className='col-md-2  content-product-p text-left '>
                                            <p>
                                                {data.price_data.product_data.name}
                                            </p>
                                        </div>
                                        <div className='col-md-3  content-product-p text-left '>
                                            <p>
                                                {data.price_data.product_data.description}
                                            </p>
                                        </div>
                                        <div className='col-md-1  price-product text-center '>
                                            <p>{data.price_data.currency}</p>
                                        </div>
                                        <div className='col-md-1 price-product-color text-center' >
                                            <p>${data.price_data.unit_amount}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                    }
                </div>
            </section>
            <section className='cart'>
                <div className='container'>
                    <div className='row'>
                        {orderStripeCheck
                            ? orderStripe.map((data, index) => (
                                < div key={index} className='col-md-12  cart-content padding-ckech-out mt-4' >
                                    <div className='row'>
                                        <div className='col-md-6 padding-content-check-out justify-content-center align-self-center'>
                                            <h2>Shipping Adress</h2>
                                            <p><b>Name: </b>{data.shipping.name}</p>
                                            <p><b>address: </b>{data.shipping.address.line1}</p>
                                            <p><b>address: </b>{data.shipping.address?.line2}</p>
                                            <p><b>postal code: </b>{data.shipping.address.postal_code}</p>
                                            <p><b>state: </b>{data.shipping.address.state}</p>
                                            <p><b>city: </b>{data.shipping.address.city}</p>
                                            <p><b>country: </b>{data.shipping.address.country}</p>

                                        </div>
                                        <div className='col-12 col-md-6  padding-content-check-out check-table '>
                                            <div className='row'>
                                                <div className='col-12 col-md-10 text-center'>
                                                    <h2>Payment</h2>
                                                    <table className="table ">
                                                        <thead>
                                                            <tr>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <th>status</th>
                                                                <th>{data?.status}</th>
                                                            </tr>
                                                            <tr>
                                                                <th>Method</th>
                                                                <th>{data?.payment_method_types}</th>
                                                            </tr>
                                                            <tr>
                                                                <th>Discount</th>
                                                                <th>0</th>
                                                            </tr>
                                                            <tr>
                                                                <th>Tax</th>
                                                                <th>0</th>
                                                            </tr>
                                                            <tr>
                                                                <th>total </th>
                                                                <th>{data?.currency} {data.amount}</th>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                            : <div className='col-12 col-md-6  padding-content-check-out check-table '>
                                <div className='row'>
                                    <div className='col-12 col-md-10 text-center'>
                                        <h2>Payment not found</h2>
                                    </div>
                                </div>
                                </div>
                        }

                            </div>
                </div>
            </section >
        </>

    )
}
export { Voucher }