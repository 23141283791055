import React, { useState, useEffect, useContext } from 'react';
import CartProvider from '../../../context/cartLocalStorage';
import queryString from 'query-string';

//svg
import btnTrash from '../../../assets/images/cart/trash.svg';
import shop_home from '../../../assets/images/cart/shop_home.svg';
import msj_banner from '../../../assets/images/cart/msj_banner.svg';
import btnCoupon from '../../../assets/images/cart/coupon.svg';

//router dom
import { NavLink, useNavigate, Link, useLocation, useParams } from 'react-router-dom';

//stripe
import StripeService from "../../../services/stripe.service";

//auth
import {useAuth} from "../../../context/AuthProvider";


function Cart() {
    const banner = require('../../../assets/images/products/background-select-product.jpg')
    const imgProduct = require('../../../assets/images/products/op3-a.png')

    //use history router
    const navigate = useNavigate();
    const location = useLocation();

    const { deleteTodo } = useContext(CartProvider);
    const { order, setOrder, itemsCart, countItemsCart, prices } = React.useContext(CartProvider);

    //auth
    const [btnAuth, setBtnAuth] = useState([false]);
    const [arrAuth, setArrAuth] = useState([false]);
    const auth = useAuth();

    //total cart
    const [total, setTotal] = useState([]);
    const [itemsTotal, setitemsTotal] = useState([itemsCart]);
    const [sumTotal, setSumTotal] = useState(0);

    //spinner 
    const [spinnerInput, setSpinnerInput] = useState(0);

    //message stripe
    const [message, setMessage] = useState(null);
    useEffect(() => {
        //check url
        const parsed = queryString.parse(location.search);
        if (parsed.success) {
            setMessage({ error: false, msg: "Order placed! You will receive an email confirmation." });
        }
        if (parsed.canceled) {
            setMessage({ error: false, msg: "Order canceled -- continue to shop around and checkout when you're ready." });
        }

        checkLogIn();
        totalCart(itemsCart);

    }, [itemsCart]);

    //delete product
    const deleteProduct = (product) => {
        const localOrder = JSON.parse(localStorage.getItem('CART'));
        const todoIndex = localOrder.findIndex(todo => todo.product === product);
        if (product !== -1) {
            localOrder.splice(product, 1);
            setOrder([...localOrder]);
        }
        deleteTodo(product);
    }

    //input spinner 
    const handleInputChange = (event) => {
        setSpinnerInput(event.target.value);
    }
    const handleIncrease = () => {
        setSpinnerInput(spinnerInput + 1);
    }
    const handleDecrease = () => {
        setSpinnerInput(spinnerInput - 1);
    }
    //total amount
    const totalCart = (itemsCart) => {
        let arrSum = prices();
        const numberArray = arrSum.map(str => {
            return parseFloat(str)
        });
        let summ = null;
        for (let index = 0; index < numberArray.length; index++) {
            summ += numberArray[index];
        }
       // const strSum=summ;
       // const roundedSum = strSum.toFixed(2);
       //console.log(summ)

        setSumTotal(summ);
    }

    //check log in
    const checkLogIn = () => {
        if (JSON.parse(sessionStorage.getItem('auth'))) {
            setArrAuth(JSON.parse(sessionStorage.getItem('auth')));
            setBtnAuth(true);
        }
    }

    //rendirect log in
    const handleLoginClick = () => {
        navigate("/login");
    }
    const checkOutStripe = async (e) => {
        console.log('klk')
        try {
            const getProduct = await StripeService.sendCheckOut(itemsCart);

        } catch (error) {

        }
    }

    return (
        <>
            <section className='cart'>
                <div className='header mt-4'>
                    <h1 className='text-center'>Shopping <b>Cart</b></h1>
                    {message ?
                        <div className={`alert aletr-auth-login alert-${message.error ? 'danger' : 'success'}`} role="alert">
                            <strong>{message.msg}</strong>
                        </div>
                        : null
                    }
                </div>

                <div className='container'>
                    {
                        itemsCart.map((item, i) => {
                            return (
                                <div className='row' key={item.id} >
                                    <div className='col-md-12 cart-content mt-4'>
                                        <div className='col-md-12 d-flex mt-3 header-cart '>
                                            <div className="form-check">
                                                <input className="form-check-input input-cart-check" type="checkbox" value="" id="flexCheckDefault" />
                                            </div>
                                            <img className='img-shop-home mx-2' src={shop_home} />
                                            <p className='mx-2'>Export Store</p>
                                            <img className='img-shop-home' src={msj_banner} />
                                        </div>
                                        <div className='col-md-12 content-product '>
                                            <div className='row mt-4'>
                                                <div className='detail-product col-md-1 '>
                                                    <div className="form-check">
                                                        <input className="form-check-input input-cart-check" type="checkbox" value="" id="flexCheckDefault" />
                                                    </div>
                                                </div>
                                                <div className='col-md-2'>
                                                    <img className='img-product-cart ' src={item.baseImageURL} />
                                                </div>
                                                <div className='col-md-2  content-product-p text-left '>
                                                    <p>
                                                    <b>{item.name}</b>, {item.description}
                                                    </p>
                                                </div>
                                                <div className='col-md-1  variatios-product text-center'>
                                                    <p>{item.options}</p>
                                                </div>
                                                <div className='col-md-1  price-product text-center '>
                                                    <p>US {item.price}</p>
                                                </div>
                                                <div className='col-md-2 '>
                                                    <div className="input-group mb-3 spinner  text-center">
                                                        <button className="btn btn-spinner" type="button" onClick={handleDecrease}><i className="fa-solid fa-plus"></i></button>
                                                        <input type="number" className="form-control" value={spinnerInput} onChange={handleInputChange} />
                                                        <button className="btn btn-spinner" type="button" onClick={handleIncrease}><i className="fa-solid fa-plus"></i></button>
                                                    </div>
                                                </div>
                                                <div className='col-md-1 price-product-color text-center' >
                                                    <p>
                                                        US {item.price}
                                                    </p>
                                                </div>
                                                <div className='col-md-1 text-center' >
                                                    <img onClick={() => deleteProduct(i)} className='btn-crash-product ' src={btnTrash} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-12 d-flex p-2 mt-3  cart-footer '>
                                            <div className='mt-3 d-flex'>
                                                <img className='img-shop-home mx-2' src={btnCoupon} />
                                                <p className='mx-2'>Up to 35% off coupon available <NavLink> More Coupons</NavLink></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        )}
                </div>

            </section>
            <section className='cart'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12  cart-content padding-ckech-out mt-4'>
                            <div className='row'>
                                <div className='col-md-6 padding-content-check-out justify-content-center align-self-center'>
                                    <p>The Shade Store has partnered with the Arbor Day Foundation to
                                        plant a tree in one of our national forests in the name of every
                                        person who places an order with us. We are fully committed to using eco-friendly
                                        materials from sustainable resources – and thanks to our customers – the world is a
                                        little greener.</p>
                                </div>
                                <div className='col-12 col-md-6  padding-content-check-out check-table '>
                                    <div className='row'>
                                        <div className='col-12 col-md-10 text-center'>
                                            <table className="table ">
                                                <thead>
                                                    <tr>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <th>Product</th>
                                                        <th>0</th>
                                                    </tr>
                                                    <tr>
                                                        <th>Discount</th>
                                                        <th>0</th>
                                                    </tr>
                                                    <tr>
                                                        <th>Tax</th>
                                                        <th>0</th>
                                                    </tr>
                                                    <tr>
                                                        <th>total </th>
                                                        <th>{sumTotal}</th>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className='col-md-12 mt-5 text-center'>
                                        {
                                             auth.authVerify()
                                                ? <form action={process.env.REACT_APP_URL_STRIPE} method="POST">
                                                    <input name="auth" type="hidden" value={JSON.stringify({ arrAuth })} />
                                                    <input name="order" type="hidden" value={JSON.stringify({ itemsCart })} />
                                                    <button className='w-75 btn btn-checkout mb-3' type="submit"> Check Out</button>
                                                </form>
                                                : <Link className='w-75 btn btn-checkout mb-3' to="/login" state={{ from: '/cart' }}>Log in to Check Out</Link>
                                        }
                                    </div>
                                    <div className='col-md-12 text-center'>
                                        <button className='w-75 btn btn-checkout '> Continue Shopping</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )

}

export { Cart };
